import { SvgDeadlockLogo } from "@ugitgud/legos/ui/icon/SVG/DeadlockLogo";
import { ReactComponent as RightArrow } from "svg/right-arrow-head.svg";

const TockersPromo = () => {
  return (
    <a href="https://u.gg/deadlock/?utm_source=ugghometopbanner" className="deadlock-promo-container">
      <div className="content-container">
        <div className="logo-and-text-container">
          <SvgDeadlockLogo className="deadlock-logo" />
          <div className="middle-section">
            <span className="top-text">THE BEST DEADLOCK STATS ARE HERE</span>
            <span className="bottom-text">Builds and tier lists for all of your favorite heroes.</span>
          </div>
        </div>
        <div className="cta-button">
          <span>Check out Deadlock</span> <RightArrow className="arrow-icon" />
        </div>
      </div>
    </a>
  );
};

export default TockersPromo;
