import * as React from "react";
import type { SVGProps } from "react";
export const SvgDeadlockLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 36 36" {...props}>
    <path fill="#EFDEC0" d="M34.2 18c0 8.947-7.253 16.2-16.2 16.2S1.8 26.947 1.8 18 9.053 1.8 18 1.8 34.2 9.053 34.2 18Z" />
    <path fill="#242021" fillRule="evenodd" d="M34.2 18.248H1.8v-.496h32.4v.496Z" clipRule="evenodd" />
    <path fill="#242021" fillRule="evenodd" d="M18.248 1.8v32.4h-.496V1.8h.496Z" clipRule="evenodd" />
    <path fill="#242021" fillRule="evenodd" d="m8.389 8.038 19.589 19.589-.351.35L8.038 8.39l.35-.351Z" clipRule="evenodd" />
    <path fill="#242021" fillRule="evenodd" d="M27.977 8.389 8.39 27.977l-.35-.35 19.588-19.59.35.352Z" clipRule="evenodd" />
    <path
      fill="#242021"
      fillRule="evenodd"
      d="m7.999 18 5.827-4.133c1.818-1.074 5.285-2.102 8.307 0 1.263.879 2.437 1.676 3.417 2.343 1.233.837 2.16 1.468 2.575 1.79H7.999Zm0 0 5.827 4.133c1.818 1.074 5.285 2.102 8.307 0 1.263-.879 2.437-1.677 3.417-2.343 1.233-.838 2.16-1.468 2.575-1.79H7.999Z"
      clipRule="evenodd"
    />
    <path fill="#EFDEC0" d="M22.133 18a4.133 4.133 0 1 1-8.266 0 4.133 4.133 0 0 1 8.265 0Z" />
    <path fill="#231F20" d="M20.314 18a2.314 2.314 0 1 1-4.628 0 2.314 2.314 0 0 1 4.628 0Z" />
    <path
      fill="#242021"
      fillRule="evenodd"
      d="M18 31.638c7.532 0 13.638-6.106 13.638-13.638S25.532 4.362 18 4.362 4.362 10.468 4.362 18 10.468 31.638 18 31.638Zm0 .496c7.806 0 14.134-6.328 14.134-14.134S25.806 3.866 18 3.866 3.866 10.194 3.866 18 10.194 32.134 18 32.134Z"
      clipRule="evenodd"
    />
  </svg>
);
